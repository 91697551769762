import { Injectable } from "@angular/core";
import { Http, Response, URLSearchParams, Headers, RequestOptions } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { API_URL } from "../../../constants";

@Injectable()
export class CashbackSupporterService {
    constructor(private http: Http) {}

    verifySupporter(code: String) {
        let params = {
            verifyCode: code,
        };
        return this.http
            .post(`${API_URL}/bonus/supporter/verify`, params)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupInfoByCode(code: String) {
        return this.http
            .get(`${API_URL}/bonus/supporter/groupInfo?supporterCode=${code}`)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    getGroupInfoByGroupId(groupId: String, authCode: String) {
        let opts = new RequestOptions();
        if (authCode) {
            let headers = new Headers();
            headers.append("X-Spond-SupporterAuth", authCode.toString());
            opts.headers = headers;
        }

        return this.http
            .get(`${API_URL}/group/${groupId}/info`, opts)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }

    signupToSupport(code: String, email: String, name: String, cardNumber: String) {
        let params = {
            email: email,
            name: name,
            cardNumber: cardNumber,
            type: "MAINCARD_BANK_AXEPT",
            supporterCode: code,
        };

        let url = `${API_URL}/bonus/supporter/signup`;
        return this.http
            .post(url, params)
            .map((res) => res.json())
            .catch((err) => {
                return Observable.throw(err.json());
            });
    }
}
