import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { LocalizationService } from "../../services/localization";
import { AccountChooseItem, accountType } from "./define";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { CashbackAddAccountJumpTipComponent } from "./cashback-add-account-jump-tip/cashback-add-account-jump-tip.component";

@Component({
    selector: "cashback-add-bank-account",
    template: require("./cashback-add-bank-account.component.html").default,
    styles: [require("../../styles/index.scss").default, require("./cashback-add-bank-account.component.scss").default],
})
export class CashbackAddBankAccountComponent implements OnInit, OnChanges {
    @Input() verifierMap: Map<accountType, string> = new Map();
    @Input() accountSelectorSource: accountType = "TINK";
    accountItemData: AccountChooseItem[] = [];
    tipTemplate = {
        type: "TINK",
    };
    modalBody: TemplateRef<any> = null;
    modalTitle = "";
    get i18n(): LocalizationService {
        return this._i18n;
    }

    private modalRef: BsModalRef = null;
    @ViewChild("modalComponent")
    private modalComponent: TemplateRef<any>;
    @ViewChild("tipComponent")
    private tipComponent: TemplateRef<CashbackAddAccountJumpTipComponent>;

    constructor(
        private _i18n: LocalizationService,
        private modalService: BsModalService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.verifierMap) {
            this.initAccountItemData(this.verifierMap);
        }
    }

    ngOnInit() {}

    onChooseAccountType(type: accountType) {
        this.tipTemplate = { type: type };
        this.modalBody = this.tipComponent;
        this.modalTitle = this._i18n.dict()["bonus_bankcards_add_bankcard_action"];
        this.showModal(this.modalComponent);
    }

    hideModal() {
        this.modalRef.hide();
    }

    private showModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    private initAccountItemData(verifierMap: Map<accountType, string>) {
        const accountItemData = [];
        if (verifierMap.has("VIPPS")) {
            accountItemData.push({
                type: "VIPPS",
                iconPath: require("./assets/iconVippsAppLarge.svg"),
                titleText: this._i18n.dict()["bonus_add_bankcard_from_vipps_title"],
                descriptionText: this._i18n.dict()["bonus_add_bankcard_from_vipps_subtitle"],
            });
        }
        if (verifierMap.has("TINK")) {
            const item = {
                type: "TINK",
                iconPath: require("./assets/iconBankAppLarge.svg"),
                titleText: this._i18n.dict()["bonus_add_bankcard_from_tink_title"],
                descriptionText: this._i18n.dict()["bonus_add_bankcard_from_tink_subtitle"],
            };
            this.accountSelectorSource === "TINK" ? accountItemData.push(item) : accountItemData.unshift(item);
        }
        this.accountItemData = accountItemData;
    }
}
