import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { accountType } from "../define";
import { LocalizationService } from "../../../services/localization";

@Component({
    selector: "cashback-account-selector-error-modal",
    template: require("./cashback-account-selector-error-modal.component.html").default,
    styles: [
        require("../../../styles/index.scss").default,
        require("./cashback-account-selector-error-modal.component.scss").default,
    ],
})
export class CashbackAccountSelectorErrorModalComponent implements OnInit {
    @Input() verifierMap: Map<accountType, string> = new Map();
    accountSelectorSource: accountType = "VIPPS";
    @ViewChild("modal")
    modal: ModalDirective;

    get i18n() {
        return this._i18n;
    }

    constructor(private _i18n: LocalizationService) {}

    ngOnInit() {}

    hide() {
        this.modal.hide();
    }

    show() {
        this.modal.show();
    }

    goToInitiationUrl(type: accountType) {
        const url = this.verifierMap.get(type);
        window.location.href = url;
    }
}
