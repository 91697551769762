import { Component, Inject, Input, OnChanges, OnInit, Optional, SimpleChanges } from "@angular/core";
import { AccountTipItem, accountType } from "../define";
import { LocalizationService } from "../../../services/localization";

@Component({
    selector: "cashback-add-account-jump-tip",
    template: require("./cashback-add-account-jump-tip.component.html").default,
    styles: [
        require("../../../styles/index.scss").default,
        require("./cashback-add-account-jump-tip.component.scss").default,
    ],
})
export class CashbackAddAccountJumpTipComponent implements OnInit, OnChanges {
    @Input() type: accountType = "VIPPS";
    @Input() verifierMap: Map<accountType, string> = new Map();
    tipsData: AccountTipItem[] = [];
    modalTitle = "";

    public get tinkLink(): string {
        return `https://link.tink.com/terms-and-conditions/${this.localeCode}`;
    }

    private get localeCode(): string {
        if (typeof this._localeCode === "string") {
            return this._localeCode;
        }
        return "en";
    }

    constructor(
        private _i18n: LocalizationService,
        @Optional() @Inject("localeCode") private _localeCode: string,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.verifierMap) {
            this.init();
        }
    }

    ngOnInit() {}

    getI18n(key: string) {
        return this._i18n.dict()[key];
    }

    goToInitiationUrl(url) {
        window.location.href = url;
    }

    private init() {
        this.tipsData = [
            {
                type: "VIPPS",
                iconPath: require("../assets/iconVippsAppLarge.svg"),
                titleText: this.getI18n("bonus_add_bankcard_from_vipps"),
                descriptionText: this.getI18n("bonus_add_bankcard_from_vipps_description"),
                actionButtonText: this.getI18n("bonus_add_bankcard_from_vipps_action"),
                initiationUrl: this.verifierMap.get("VIPPS"),
            },
            {
                type: "TINK",
                iconPath: require("../assets/iconBankAppLarge.svg"),
                tipsIcon: require("../assets/iconThinkSmall.png"),
                titleText: this.getI18n("bonus_add_bankcard_from_tink"),
                descriptionText: this.getI18n("bonus_add_bankcard_from_tink_description"),
                actionButtonText: this.getI18n("bonus_add_bankcard_from_tink_action"),
                tip: this.getI18n("bonus_add_bankcard_from_tink_tips"),
                initiationUrl: this.verifierMap.get("TINK"),
            },
        ];
    }
}
