import { Component, OnInit } from "@angular/core";

@Component({
    selector: "layout",
    template: require("./layout.component.html").default,
    styles: [require("../../../common/styles/index.scss").default, require("./layout.component.scss").default],
})
export class LayoutComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
