import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CashbackAddBankAccountComponent } from "./cashback-add-bank-account.component";
import { CashbackAddAccountJumpTipComponent } from "./cashback-add-account-jump-tip/cashback-add-account-jump-tip.component";
import { ModalModule } from "ngx-bootstrap";
import { SpondCommonModule } from "../../common.module";
import { CashbackAccountSelectorComponent } from "./cashback-account-selector/cashback-account-selector.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormattedBankAccountNumberModule } from "../../pipes/formatted-bank-account-number/formatted-bank-account-number.module";
import { SpinnerButtonModule } from "../spinnerButton";
import { CashbackAccountSelectorErrorModalComponent } from "./cashback-account-selector-error-modal/cashback-account-selector-error-modal.component";

const component = [
    CashbackAddBankAccountComponent,
    CashbackAddAccountJumpTipComponent,
    CashbackAccountSelectorComponent,
    CashbackAccountSelectorErrorModalComponent,
];

@NgModule({
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        SpondCommonModule,
        FormsModule,
        ReactiveFormsModule,
        FormattedBankAccountNumberModule,
        SpinnerButtonModule,
    ],
    declarations: component,
    exports: component,
})
export class CashbackAddBankAccountModule {}
