import { hostnames } from "../../constants";
import { Injectable } from "@angular/core";
import mixpanel from "mixpanel-browser";

@Injectable()
export class MixpanelService {
    internal: boolean = false;
    mixpanel: any;

    constructor() {
        mixpanel.init(this.mixpanelToken(), {
            debug: window.location.hostname !== "spond.com",
            ignore_dnt: true,
        });
        console.log(this.mixpanel);
        this.track("Launched client 2");
    }

    mixpanelToken = function () {
        if (window.location.hostname === hostnames.prod) {
            return "3f2910373ac2ceec721256135e1f5793";
        } else {
            return "65dd269ccb90c03f13193709b2c5fdb7";
        }
    };

    track = function (event: string, properties?: Record<string, unknown>) {
        mixpanel.track(event, {
            ...properties,
            platform: "Landing",
        });
    };
}
