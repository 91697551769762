import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormattedBankAccountNumberPipe } from "./formatted-bank-account-number.pipe";

@NgModule({
    imports: [CommonModule],
    declarations: [FormattedBankAccountNumberPipe],
    exports: [FormattedBankAccountNumberPipe],
})
export class FormattedBankAccountNumberModule {}
