import { NgModule } from "@angular/core";
import { ModalModule } from "ng2-bootstrap/modal";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { SpinnerButtonModule } from "../../../common/components/spinnerButton";
import { SupporterComponent } from "./supporter.component";
import { SpondCommonModule } from "../../../common/common.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmModalModule } from "../../../common/components/confirmModal";
import { CashbackSupporterService } from "../../../common/services/cashbackSupporter";
import { CashbackService } from "../../../common/services/cashback";
import { CurrencyService } from "../../../common/services/currency";
import { MixpanelService } from "../../../common/services/mixpanel";
import {
    CashbackAddBankAccountService,
    CashbackAddBankAccountModule,
    PROVIDE_NAME,
} from "../../../common/components/cashback-add-bank-account";
import { API_URL } from "../../../constants";

@NgModule({
    declarations: [SupporterComponent],
    imports: [
        ModalModule,
        CommonModule,
        SpondCommonModule,
        BrowserAnimationsModule,
        SpinnerButtonModule,
        FormsModule,
        ConfirmModalModule,
        CashbackAddBankAccountModule,
    ],
    providers: [
        { provide: "HTTP_URL_PREFIX", useValue: API_URL },
        { provide: PROVIDE_NAME, useClass: CashbackAddBankAccountService },
        CashbackSupporterService,
        CashbackService,
        CurrencyService,
        MixpanelService,
    ],
    exports: [SupporterComponent],
})
export class SupporterModule {}
