import { Routes } from "@angular/router";

import { SupporterComponent } from "../supporter/supporter.component";
import { LayoutComponent } from "../layout/layout.component";
import { CatchVerifiersStateGuard } from "../catch-verifiers-state.guard";

export const rootRouterConfig: Routes = [
    {
        path: "",
        component: LayoutComponent,
        canActivate: [CatchVerifiersStateGuard],
        children: [{ path: ":supporterCode", component: SupporterComponent }],
    },
];
