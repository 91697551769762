import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "FormattedBankAccountNumber",
})
export class FormattedBankAccountNumberPipe implements PipeTransform {
    transform(value: any, args: "full"): any {
        if (value.length !== 11) {
            return value;
        }
        switch (args) {
            case "full":
                value = this.insertStr(value, 6, " ");
                value = this.insertStr(value, 4, " ");
                break;
            default:
                break;
        }
        return value;
    }

    private insertStr(soure, start, newStr) {
        return soure.slice(0, start) + newStr + soure.slice(start);
    }
}
