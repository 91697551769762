import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { animate, style, transition, trigger } from "@angular/animations";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ConfirmModalComponent, DeferredClickEvent } from "../../../common/components/confirmModal";
import { SpinnerButtonComponent } from "../../../common/components/spinnerButton";
import { LocalizationService } from "../../../common/services/localization";
import { CashbackSupporterService } from "../../../common/services/cashbackSupporter";
import { BONUS_ERROR_CODE, CASHBACK_PARTNER_TYPE, CashbackService } from "../../../common/services/cashback";
import { MixpanelService } from "../../../common/services/mixpanel";
import { AccountsMap, accountType } from "../../../common/components/cashback-add-bank-account/define";
import {
    IService,
    LoadAccountsParams,
    LoadCashbackVerifierListParams,
    PROVIDE_NAME,
    SelectAccountsParams,
} from "../../../common/components/cashback-add-bank-account";
import { getCustomUrlFromInitiationUrl } from "../../../common/components/cashback-add-bank-account/cashback-add-bank-account-service.define";
import { concat, map, mergeAll } from "rxjs/operators";
import { Observable } from "rxjs/Rx";
import { from } from "rxjs/observable/from";
import { empty } from "rxjs/observable/empty";

enum CashbackSupporterState {
    SupportGroup = 1,
    InputContactInfo = 10,
    InputCardInfo = 11,
    AccountSelector,
    AccountNone,
    Confirmation = 20,
    SupportGroupSuccess = 21,
    BonusDisabledError = 31,
    GeneralError = 99,
}

@Component({
    selector: "cashback-supporter",
    template: require("./supporter.component.html").default,
    styles: [require("../../../common/styles/index.scss").default, require("./supporter.component.scss").default],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("fadeInAnimation", [
            transition(":enter", [style({ opacity: 0 }), animate(".5s 400ms", style({ opacity: 1 }))]),
        ]),
        trigger("fadeOutAnimation", [
            transition(":leave", [style({ opacity: 1 }), animate(".5s", style({ opacity: 0 }))]),
        ]),
    ],
})
export class SupporterComponent implements OnInit {
    state: CashbackSupporterState;
    group: any = null;
    storePartners: any = null;
    subscribePartner: any = null;
    name = "";
    generalErrorTitle: string = null;
    generalErrorDescription: string = null;
    assetFjordkraftIconUrl: any = require("./assets/cashback-logo-fjordkraft.png");
    CashbackSupporterState = CashbackSupporterState;
    BONUS_ERROR_CODE = BONUS_ERROR_CODE;
    userEmail = "";
    cards: any[];
    verifierMap: Map<accountType, string> = new Map();
    accountsMap: AccountsMap = new Map();
    selectedAccounts: Array<string> = [];
    accountSelectorSource: "VIPPS" | "TINK" = "TINK";

    get i18n(): LocalizationService {
        return this._i18n;
    }

    private get redirectUrl() {
        return window.location.href.split(this.supporterCode)[0];
    }

    private groupId: string = null;
    private initialized = false;
    private supporterCode: string = null;
    private authCode: string = null;
    private profileId: string = null;
    @ViewChild("btnSave")
    private btnSave: SpinnerButtonComponent;
    @ViewChild("confirmErrorModal")
    private confirmErrorModal: ConfirmModalComponent;
    @ViewChild("userCheckEmail")
    private userCheckEmail: any;

    constructor(
        private _i18n: LocalizationService,
        private titleService: Title,
        private supporterService: CashbackSupporterService,
        private cashbackService: CashbackService,
        private router: Router,
        private route: ActivatedRoute,
        private mixpanelService: MixpanelService,
        @Inject("localeCode") private _localeCode: string,
        @Inject(PROVIDE_NAME) private cashbackAddBankAccountService: IService,
    ) {}

    ngOnInit() {
        this._i18n.setLocale(this._localeCode);
        this.route.queryParams.subscribe((params) => {
            this.authCode = params["authCode"];
            this.profileId = params["profileId"];
            if (!this.initialized) {
                this.initialize()
                    .pipe(mergeAll(), concat(this.loadAccounts(params)))
                    .subscribe(
                        () => {},
                        (error) => {
                            this.errorHandler(error);
                        },
                    );
            }
        });
        this.state = CashbackSupporterState.SupportGroup;
        this.mixpanelService.track("Showed cashback supporter landing page");
    }

    onSaveClicked() {
        if (this.selectedAccounts.length === 0) {
            return;
        }
        const param: SelectAccountsParams = {
            type: "BANK_AXEPT",
            accounts: {
                options: this.selectedAccounts.map((item) => {
                    return { accountNumber: item };
                }),
            },
        };
        this.cashbackAddBankAccountService.selectAccounts(param).subscribe(
            () => {
                this.clearParam();
                this.state = CashbackSupporterState.Confirmation;
            },
            (error) => {
                this.errorHandler(error);
            },
        );
    }

    onOrderClicked() {
        window.location.href =
            window.location.origin + `/landing/fjordkraft-order?profileId=${this.profileId}&authCode=${this.authCode}`;
    }

    onConfirmErrorClicked(event: DeferredClickEvent) {
        event.success();
    }

    onNextClicked() {
        if (!!this.authCode) {
            this.state = CashbackSupporterState.InputCardInfo;
        } else if (this.state == CashbackSupporterState.SupportGroup) {
            this.state = CashbackSupporterState.InputContactInfo;
        } else if (this.state == CashbackSupporterState.InputContactInfo) {
            if (this.userCheckEmail.invalid) {
                this.confirmErrorModal.show(
                    this._i18n.dict().general_title_error,
                    this._i18n.dict().error_invalid_email,
                    null,
                    this._i18n.dict().general_ok,
                    false,
                );
                return;
            }
            this.loadCashbackVerifierList();
        }
    }

    private loadAccounts(queryParams: Params): Observable<any> {
        queryParams["code"] && (this.accountSelectorSource = "VIPPS");
        queryParams["account_verification_report_id"] && (this.accountSelectorSource = "TINK");
        const code = queryParams["code"] || queryParams["account_verification_report_id"];
        const state = queryParams["state"];
        if (typeof queryParams["email"] === "string") {
            this.userEmail = decodeURIComponent(queryParams["email"]);
        }
        if (typeof queryParams["name"] === "string") {
            this.name = decodeURIComponent(queryParams["name"]);
        }
        if (!!code && !!state) {
            const params: LoadAccountsParams = { redirect: this.redirectUrl, code, state };
            return this.cashbackAddBankAccountService.loadAccounts(params).pipe(
                map((accountMap: AccountsMap) => {
                    if (accountMap.size !== 0) {
                        this.accountsMap = accountMap;
                        this.state = CashbackSupporterState.AccountSelector;
                    } else {
                        this.loadCashbackVerifierList(CashbackSupporterState.AccountNone);
                    }
                }),
            );
        }
        return empty();
    }

    private initialize(): Observable<Observable<any>> {
        this.supporterCode = this.route.snapshot.params["supporterCode"];
        if (this.supporterCode.length === 32) {
            this.groupId = this.supporterCode;
        }
        if (!this.authCode && this.groupId) {
            return;
        }
        this.initialized = true;
        const initializeStreams = [];
        if (!!this.groupId) {
            initializeStreams.push(this.getGroupInfoByGroupId());
            initializeStreams.push(this.getCards());
        } else {
            initializeStreams.push(this.getGroupInfoByCode());
        }
        initializeStreams.push(this.retrievePartners());
        return from(initializeStreams);
    }

    private loadCashbackVerifierList(pageState: CashbackSupporterState = CashbackSupporterState.InputCardInfo) {
        const redirectUrl = this.redirectUrl;
        const params: LoadCashbackVerifierListParams = {
            supporterCode: this.supporterCode,
            name: this.name,
            email: this.userEmail,
            redirect: redirectUrl,
            type: "BANK_AXEPT",
        };
        this.btnSave && (this.btnSave.isSpinning = true);
        this.cashbackAddBankAccountService.loadCashbackVerifierList(params).subscribe(
            (res) => {
                res.forEach((initiationUrl, verifier) => {
                    res.set(
                        verifier,
                        getCustomUrlFromInitiationUrl(
                            initiationUrl,
                            new Map([
                                ["supporterCode", this.supporterCode],
                                ["email", this.userEmail],
                                ["name", this.name],
                            ]),
                        ),
                    );
                });
                this.verifierMap = res;
                this.state = pageState;
            },
            (error) => {
                this.errorHandler(error);
            },
            () => {
                this.btnSave && (this.btnSave.isSpinning = false);
            },
        );
    }

    private getGroupInfoByGroupId(): Observable<any> {
        return this.supporterService.getGroupInfoByGroupId(this.groupId, this.authCode).pipe(
            map((group) => {
                this.group = group;
                return group;
            }),
        );
    }

    private getGroupInfoByCode(): Observable<any> {
        return this.supporterService.getGroupInfoByCode(this.supporterCode).pipe(
            map((group) => {
                this.group = group;
                return group;
            }),
        );
    }

    private getCards(): Observable<any> {
        return this.cashbackService.getCards(this.authCode).pipe(
            map((cards: any[]) => {
                this.cards = cards;
                return cards;
            }),
        );
    }

    private retrievePartners() {
        return this.cashbackService.retrievePartners().pipe(
            map((partners: any) => {
                this.storePartners = partners.retail;
                this.subscribePartner = partners.subscription.find(
                    (partner) => partner.type === CASHBACK_PARTNER_TYPE.SUBSCRIPTION,
                );
                return partners;
            }),
        );
    }

    private errorHandler(error) {
        if (this.btnSave) {
            this.btnSave.isSpinning = false;
        }

        if (error.errorCode == BONUS_ERROR_CODE.BONUS_DISABLED) {
            this.state = CashbackSupporterState.BonusDisabledError;
        } else if (error.errorCode == BONUS_ERROR_CODE.BONUS_CARD_ALREADY_REGISTERED) {
            this.state = CashbackSupporterState.GeneralError;
            this.generalErrorTitle = this._i18n.dict().bonus_add_bankcard_already_registered_title;
            this.generalErrorDescription = this._i18n.dict().bonus_add_bankcard_already_registered_description;
        } else if (error.errorCode == BONUS_ERROR_CODE.BONUS_INVALID_ACCOUNT_NUMBER) {
            this.state = CashbackSupporterState.GeneralError;
            this.generalErrorTitle = this._i18n.dict().bonus_add_bankcard_invalid_title;
            this.generalErrorDescription = this._i18n.dict().bonus_add_bankcard_invalid_description;
        } else if (error.errorCode == BONUS_ERROR_CODE.BONUS_INVALID_NAME) {
            this.state = CashbackSupporterState.GeneralError;
            this.generalErrorTitle = this._i18n.dict().bonus_group_add_account_number_invalid_name_title;
            this.generalErrorDescription = this._i18n.dict().bonus_group_add_account_number_invalid_name_description;
        } else {
            this.state = CashbackSupporterState.GeneralError;
            this.generalErrorTitle = this._i18n.dict().general_title_error;
            this.generalErrorDescription = this._i18n.dict().general_unknown_error_description;
        }
    }

    private clearParam() {
        this.router.navigate(["."], { relativeTo: this.route });
    }
}
