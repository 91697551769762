import "../../../common/rxjs-operators";

import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { rootRouterConfig } from "./root.routes";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { HttpModule } from "@angular/http";

import { ModalModule } from "ng2-bootstrap/modal";

import { SharedModule } from "../shared/shared.module";
import { SpondCommonModule } from "../../../common/common.module";

import { RootComponent } from "./root.component";
import { SupporterModule } from "../supporter/supporter.module";
import { CommonModule } from "@angular/common";

import { HttpClientModule } from "@angular/common/http";
import { LayoutModule } from "../layout/layout.module";
import { CatchVerifiersStateGuard } from "../catch-verifiers-state.guard";

@NgModule({
    declarations: [RootComponent],
    imports: [
        BrowserModule,
        CommonModule,
        SpondCommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        ModalModule.forRoot(),
        SupporterModule,
        LayoutModule,
        RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    ],
    providers: [{ provide: "localeCode", useValue: "nb" }, CatchVerifiersStateGuard],
    bootstrap: [RootComponent],
})
export class RootModule {}
