import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { AccountsMap } from "../define";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { LocalizationService } from "../../../services/localization";
import { BsModalRef, BsModalService } from "ngx-bootstrap";

@Component({
    selector: "cashback-account-selector",
    template: require("./cashback-account-selector.component.html").default,
    styles: [
        require("../../../styles/index.scss").default,
        require("./cashback-account-selector.component.scss").default,
    ],
})
export class CashbackAccountSelectorComponent implements OnInit {
    @Input() isModal = false;
    selectorForm: FormGroup;
    @Input() set accountsMap(v) {
        this._accountsMap = v;
        this.accountOptionCategories = Array.from(v).sort();
    }
    get accountsMap(): AccountsMap {
        return this._accountsMap;
    }
    accountOptionCategories = [];
    private _accountsMap: AccountsMap = new Map();
    @Input()
    selectedValue: Array<string> = [];
    @Output()
    selectedValueChange: EventEmitter<Array<string>> = new EventEmitter();

    @ViewChild("modalComponent")
    private modalComponent: TemplateRef<any>;
    private modalRef: BsModalRef = null;

    @Output()
    onConfirm: EventEmitter<Array<string>> = new EventEmitter();

    constructor(
        private fb: FormBuilder,
        private _i18n: LocalizationService,
        private modalService: BsModalService,
    ) {}

    ngOnInit() {
        this.selectorForm = this.fb.group({
            bankNumber: "",
        });
    }

    getI18n(key: string) {
        return this._i18n.dict()[key];
    }

    onSelectBankNumber(event: Event) {
        const value = event.target["defaultValue"];
        const isSelect = this.selectorForm.get("bankNumber").value;
        if (value && isSelect) {
            this.selectedValue.push(value);
        } else if (value && !isSelect) {
            this.selectedValue.splice(
                this.selectedValue.findIndex((item) => item === value),
                1,
            );
        }
        this.selectedValueChange.emit(this.selectedValue);
    }

    hideModal() {
        this.modalRef.hide();
    }

    showModal() {
        this.modalRef = this.modalService.show(this.modalComponent, { ignoreBackdropClick: true });
    }

    onSaveClicked() {
        if (this.selectedValue.length === 0) {
            return;
        }
        this.onConfirm.emit(this.selectedValue);
    }
}
