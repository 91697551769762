import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    UrlSegmentGroup,
    PRIMARY_OUTLET,
    UrlSegment,
    UrlTree,
} from "@angular/router";
import { Observable } from "rxjs/Observable";
import { getRestoredParams } from "../../common/components/cashback-add-bank-account/cashback-add-bank-account-service.define";

@Injectable()
export class CatchVerifiersStateGuard implements CanActivate {
    constructor(private router: Router) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (typeof next.queryParams.state === "string") {
            const paramState = next.queryParams.state;
            if (paramState.includes(".supporterCode.")) {
                const restoredUrlMap = getRestoredParams("supporter");
                const urlTree: UrlTree = this.router.parseUrl(state.url);
                urlTree.queryParams["state"] = restoredUrlMap.get("state");
                urlTree.queryParams["email"] = restoredUrlMap.get("email");
                urlTree.queryParams["name"] = restoredUrlMap.get("name");
                let validUrl = urlTree
                    .toString()
                    .split("?")
                    .join(restoredUrlMap.get("supporterCode") + "?");
                if (urlTree.queryParams["email"].startsWith("spondandroid1+")) {
                    // for ui test, which has already contains the supporterCode in the path
                    validUrl = urlTree.toString();
                }

                this.router.navigateByUrl(validUrl);
            }
        }
        return true;
    }
}
