import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { AccountsMap, accountType } from "../define";
import { Observable } from "rxjs/Rx";
import { map } from "rxjs/operators";
import {
    IService,
    LoadAccountsParams,
    LoadCashbackVerifierListParams,
    SelectAccountsParams,
    SelectAccountsResponse,
    Verifier,
} from "../cashback-add-bank-account-service.define";

@Injectable()
export class CashbackAddBankAccountService implements IService {
    token = "";
    private api = {
        loadCashbackVerifierList: `${this.apiUrl}/bonus/supporter/signup`,
        loadAccounts: `${this.apiUrl}/bonus/accounts`,
        selectAccounts: `${this.apiUrl}/bonus/supporter/accounts/select`,
        verifyEmail: `${this.apiUrl}/bonus/supporter/verify`,
        getCashbackVerifierList: `${this.apiUrl}/bonus/verifiers`,
        selectAccountsByRegistered: `${this.apiUrl}/bonus/sources`,
    };

    constructor(
        @Inject("HTTP_URL_PREFIX") private apiUrl: string,
        private http: HttpClient,
    ) {}

    loadCashbackVerifierList(
        params: LoadCashbackVerifierListParams,
        authCode?: string,
        type: "registered" | "unRegistered" = "unRegistered",
    ): Observable<Map<accountType, string>> {
        let httpAction: Observable<object>;
        if (type === "registered") {
            httpAction = this.http.get(this.api.getCashbackVerifierList, {
                params: this.getHttpParamsFromJson(params),
                headers: this.getHeader(authCode),
            });
        } else {
            httpAction = this.http.post(this.api.loadCashbackVerifierList, params);
        }
        return httpAction.pipe(
            map((res: Array<Verifier>) => res.map((item) => [item.verifier, item.initiationUrl])),
            map((item: Array<[accountType, string]>) => new Map(item)),
        );
    }

    loadAccounts(params: LoadAccountsParams): Observable<AccountsMap> {
        return this.http.post(this.api.loadAccounts, params).pipe(
            map((res: SelectAccountsResponse) => {
                this.token = res.token;
                const accountsMap: AccountsMap = new Map();
                const options = res.options.sort((a: any, b: any) => a.bankName - b.bankName);
                for (const option of options) {
                    if (accountsMap.has(option.bankName)) {
                        accountsMap.set(option.bankName, [...accountsMap.get(option.bankName), option]);
                    } else {
                        accountsMap.set(option.bankName || "", [option]);
                    }
                }
                return accountsMap;
            }),
        );
    }

    selectAccounts(
        params: SelectAccountsParams,
        authCode?: string,
        type: "registered" | "unRegistered" = "unRegistered",
    ): Observable<any> {
        params.accounts.token = this.token;
        if (type === "registered") {
            return this.http.post(this.api.selectAccountsByRegistered, params, { headers: this.getHeader(authCode) });
        }
        return this.http.post(this.api.selectAccounts, params);
    }

    private getHttpParamsFromJson(data: { [key: string]: any }) {
        let params = new HttpParams();
        for (const key of Object.keys(data)) {
            if (data.hasOwnProperty(key)) {
                params = params.set(key, data[key]);
            }
        }
        return params;
    }

    private getHeader(authCode: string) {
        let headers = new HttpHeaders();
        if (authCode) {
            headers = headers.set("X-Spond-SupporterAuth", authCode.toString());
        }
        return headers;
    }
}
