import { Observable } from "rxjs";
import { AccountsMap, accountType } from "./define";

export const PROVIDE_NAME = "CashbackAddBankAccountService";

/***
 * @description Unregistered Flow should be implements all Params
 * registered Flow just need redirect
 */
export interface LoadCashbackVerifierListParams {
    supporterCode?: string;
    name?: string;
    email?: string;
    redirect: string;
    type?: "BANK_AXEPT";
}
export interface Verifier {
    verifier: accountType;
    initiationUrl: string;
}
export interface LoadAccountsParams {
    redirect: string;
    code: string;
    state: string;
}
export interface SelectAccountsResponse {
    token: string;
    options: Array<{ accountName: string; accountNumber: string; bankName?: string }>;
}

export interface SelectAccountsParams {
    type: "BANK_AXEPT";
    accounts: {
        token?: string;
        options: Array<{ accountNumber: string }>;
    };
}
export interface IService {
    // cashe token when loadAccountFromRedirectUrl
    token?: string;
    loadCashbackVerifierList(
        params: LoadCashbackVerifierListParams,
        authCode?: string,
        type?: "registered" | "unRegistered",
    ): Observable<Map<accountType, string>>;
    loadAccounts(params: LoadAccountsParams): Observable<AccountsMap>;
    selectAccounts(
        params: SelectAccountsParams,
        authCode?: string,
        type?: "registered" | "unRegistered",
    ): Observable<any>;
}

export function getCustomUrlFromInitiationUrl(url: string, customString: Map<string, string>) {
    const paramsList = url.slice(url.indexOf("?") + 1).split("&");
    let uiTestParams = ["code=test"];
    paramsList.forEach((paramsSting, index) => {
        if (paramsSting.includes("state=")) {
            let customParam = "";
            customString.forEach((value, fieldName) => {
                customParam = `${customParam}.${fieldName}.${value}`;
            });
            paramsList[index] = paramsSting + customParam;
            uiTestParams = [...uiTestParams, paramsList[index]];
        }
    });
    if (window.location.href.includes("spond.dev")) {
        return `${window.location.origin}${window.location.pathname}?${uiTestParams.join("&")}`;
    } else {
        return url.split("?")[0] + "?" + paramsList.join("&");
    }
}

export function getRestoredParams(type: "cashbackAdmin" | "supporter"): Map<string, string> {
    const url = window.location.href;
    const paramsList = url.slice(url.indexOf("?") + 1).split("&");
    let state = "";
    if (type === "cashbackAdmin") {
        let authCode = "";
        let profileId = "";

        paramsList.forEach((paramsSting, index) => {
            if (paramsSting.includes("state=")) {
                const customData = paramsSting.split(".authCode.")[1].split(".profileId.");
                authCode = customData[0];
                profileId = customData[1];
                state = paramsSting.split(".authCode.")[0].split("=")[1];
            }
        });
        return new Map([
            ["authCode", authCode],
            ["profileId", profileId],
            ["state", state],
        ]);
    }

    if (type === "supporter") {
        let supporterCode = "";
        let email = "";
        let name = "";
        paramsList.forEach((paramsSting, index) => {
            if (paramsSting.includes("state=")) {
                supporterCode = paramsSting.split(".supporterCode.")[1].split(".email.")[0];
                email = paramsSting.split(".email.")[1].split(".name.")[0];
                name = paramsSting.split(".name.")[1];
                state = paramsSting.split(".supporterCode.")[0].split("=")[1];
            }
        });
        return new Map([
            ["supporterCode", supporterCode],
            ["state", state],
            ["email", email],
            ["name", name],
        ]);
    }
}

export type SUPPORT_GROUP_STATUSES =
    | "NEW"
    | "ALREADY_VERIFIED"
    | "CASHBACK_DISABLED"
    | "CARD_ALREADY_REGISTERED"
    | "TOO_MANY_CARDS"
    | "INVALID_NUMBER"
    | "VERIFIED";
