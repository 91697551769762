import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared/shared.module";

@NgModule({
    imports: [CommonModule, RouterModule, SharedModule],
    declarations: [LayoutComponent],
})
export class LayoutModule {}
